module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","font_size":"10px","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"name","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"78935a2f6fbfe059ebbd4a04ebb9a871"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
